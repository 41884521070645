import styled from 'styled-components';
import { DIMENSIONS, FONTS } from 'Styles/Constants';

export const RightContentWrapper = styled.div`
  @media print {
    * {
      background: transparent !important;
      color: black !important;
      box-shadow: none !important;
      text-shadow: none !important;
      filter: none !important;
      -ms-filter: none !important;
    }
  }
  margin-left: 0px;
  width: 100%;
  background: #fafbfc;
  font-family: ${FONTS.PRIMARY};

  .ant-layout-footer {
    float: left;
    width: 100%;
    background: #fafbfc;
    border-top: 1.5px solid #eaeaea;
  }

  .antd-content {
    padding: 70px 0px 0px;
    flex-shrink: 0;
    background: #fbfbfc none repeat scroll 0% 0%;
    position: relative;
    overflow: auto;
  }

  .ant-layout {
    background: #fafbfc;

    &.contentMainLayout {
      overflow: auto;
      overflow-x: hidden;
      // min-height: 100vh;
      @media only screen and (min-width: 768px) and (max-width: 1220px) {
        flex-shrink: 0;
      }

      @media only screen and (max-width: 767px) {
        width: 100%;
        flex-shrink: 0;
      }
    }
  }
  a {
    color: ${(props) => props.theme.color};
  }

  .ant-btn-primary {
    color: #fff;
    background: ${(props) => props.theme.color};
    border-color: ${(props) => props.theme.color};
  }

  .ant-btn {
    box-shadow: none !important;
  }

  .ant-menu-item:hover {
    color: ${(props) => props.theme.color};
  }
`;

export const ContentWrapper = styled.div`
  margin: ${DIMENSIONS.HEADER}px 0px 0;
  overflow: initial;
  padding: 15px;
`;
